'use strict'

/**
 * Return SVG mask content to be used inside an SVG DEF element
 *
 * @param {string} maskId id of the MASK element to reference on the masked media element
 * @param {string} svgId id of the top SVG element container of the content
 * @param {string} svg masking content
 * @param {{x: string|number, y: string|number, width: string|number, height: string|number}} attributes
 * @param {boolean} [isClip] if true the mask will be forced into alpha mode/type
 * @param {number} [version=1] version of SVG mask implementation to use
 * @return {string}
 */
function getMask(maskId, svgId, svg, attributes, isClip, version = 1) {
    const {width, height, x, y, transform, style} = attributes
    const transformStr = transform ? ` transform="${transform}"` : ''
    const styleStr = style ? ` style="${style}"` : ''
    let bleach = `<style>#${svgId} * {fill: #fff; stroke: #fff; stroke-width: 0;}</style>`
    let filterId

    if (version > 1) {
        filterId = `${svgId}-bleach`
        bleach = `<filter id="${filterId}"><feColorMatrix type="matrix"
    values="0 0 0 0 1
            0 0 0 0 1
            0 0 0 0 1
            0 0 0 1 0"/></filter>`
    }

    return `${isClip ? bleach : ''}
<mask id="${maskId}">
    <use ${filterId ? `filter="url(#${filterId})" ` : ''}id="${svgId}-use" xlink:href="#${svgId}" width="${width}" height="${height}" x="${x}" y="${y}"${transformStr}${styleStr}></use>
</mask>
${svg}
`
}

const symbols = /[\r\n%#()<>?\[\\\]^`{|}]/g

/**
 * Returns a data URI SVG encoded for CSS <url> value
 *
 * @param {string} svg content of the SVG
 * @return {string} encoded CSS url() value
 */
function encodeSVG(svg) {
    // Use single quotes instead of double to avoid encoding.
    const escaped = svg.replace(/"/g, '\'')
        .replace(/>\s+</g, '><')
        .replace(/\s{2,}/g, ' ')
        .replace(symbols, encodeURIComponent)

    return `url("data:image/svg+xml,${escaped}")`
}

/**
 * Returns Object with mask CSS properties
 *
 * @param {string} svg
 * @param {{x: string, y: string, width: string, height: string}} attributes
 * @return {{maskRepeat: string, maskPosition: string, maskSize: string, maskImage: string}}
 */
function getCSSMask(svg, attributes) {
    const {width, height, x, y} = attributes
    const maskImage = encodeSVG(svg)
    const maskRepeat = 'no-repeat'
    const maskPosition = `${x} ${y}`
    const maskSize = `${width} ${height}`
    const webkitPrefix = 'Webkit'

    return {
        [`${webkitPrefix}MaskImage`]: maskImage,
        [`${webkitPrefix}MaskPosition`]: maskPosition,
        [`${webkitPrefix}MaskSize`]: maskSize,
        [`${webkitPrefix}MaskRepeat`]: maskRepeat,
        maskImage,
        maskPosition,
        maskSize,
        maskRepeat
    }
}

module.exports = {
    getMask,
    getCSSMask
}
